export const fetchLeetcodeStats = async (uuid: string, leetcodeId: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API}/leetcode/${uuid}/${leetcodeId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch LeetCode stats')
    }
    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error fetching LeetCode stats:', error)
    throw error
  }
}